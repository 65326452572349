import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "page-2" }}>
    <SEO title="About ChuLyMiu" />
    <div className="page_about_wrapper">
    {/* <h1>Comming soon</h1> */}
    {/* <p>some thing about me</p> */}
    <Link to="/" style={{textDecoration: "none", color: "#d100fc", position: "absolute", top: 0}}> &nbsp; Go back to the homepage</Link>
    </div>
  </Layout>
)

export default SecondPage
